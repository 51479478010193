<template>
  <div class="fleet-item pa-16 d-flex justify-space-between align-center mb-16">
    <div class="d-flex" @click="handleJump">
      <div class="left-icon mr-8 mt-2 lh-20 text-center">
        <Icon name="wodechedui-hui" svgClass="mt-2" />
      </div>
      <div class="font-16">{{ itemData.fleetInfoVO.name }}</div>
    </div>
    <div class="d-flex flex-column justify-space-between">
      <a :href="`tel:${itemData.carrierPhone}`" class="mb-8 d-flex font-14"
        >{{ itemData.carrierPhone }} <Icon name="dh" svgClass="ml-4"
      /></a>
      <div class="text_muted font-12 text-right">车队长: {{ itemData.carrierName }}</div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import { useUserStore } from '@/store/user';
import { PageEnum } from '@/enums/pageEnum';
export default defineComponent({
  props: {
    itemData: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, ctx) {
    const useUserInfoStore = useUserStore();
    const handleJump = () => {
      if (useUserInfoStore?.userInfo?.driverId) {
        ctx.root.$router.push({
          path: PageEnum.DRIVER_FLEET_DETAIL,
          query: {
            phone: props.itemData.carrierPhone
          }
        });
      }
    };

    return {
      handleJump
    };
  }
});
</script>
<style lang="less">
.fleet-item {
  background: #ffffff;
  border-radius: 8px;
  height: 0.72rem;
  .left-icon {
    width: 0.2rem;
    height: 0.2rem;
    background: rgba(135, 135, 135, 0.1);
    border-radius: 50%;
  }
}
</style>
