<template>
  <Page :loading="loading">
    <Navbar title="我的车队" @clickLeft="handleJump(PageEnum.BASE_MINE, 'back')" :customClickLeft="true" />
    <div class="pa-16 fleet-wrap">
      <div v-if="fleet.length > 0">
        <FleetItem v-for="(item, index) in fleet" :key="index" :itemData="item" />
        <div class="btn-fix py-12 px-32">
          <Button @click="() => handleJump(PageEnum.DRIVER_FLEET_JOIN, 'prev')" size="large" type="primary"
            >加入车队</Button
          >
        </div>
      </div>
      <Empty v-else imgWidth="0.84rem" :image="require('@/assets/noFleet.png')" description="暂未加入车队">
        <template #extra>
          <Button
            @click="() => handleJump(PageEnum.DRIVER_FLEET_JOIN, 'prev')"
            class="mt-42"
            size="normal"
            type="primary"
            >加入车队</Button
          >
        </template>
      </Empty>
    </div>
  </Page>
</template>
<script>
import { defineComponent, computed, onBeforeMount, ref } from '@vue/composition-api';
import { useDriverFleetStore } from '@/store/fleet';
import { useUserStore } from '@/store/user';
import { PageEnum } from '@/enums/pageEnum';
import { Button, Toast } from 'vant';
import FleetItem from './components/FleetItem';

export default defineComponent({
  name: 'driver-fleet',
  components: {
    Button,
    FleetItem
  },
  setup(_, ctx) {
    const useDriverFleet = useDriverFleetStore();
    const useUserInfoStore = useUserStore();
    const loading = ref(true);
    const fleet = computed(() => useDriverFleet.fleet || []);

    onBeforeMount(async () => {
      Toast.loading('车队加载中....');
      await useDriverFleet.queryCarrier();
      loading.value = false;
      Toast.clear();
    });

    const handleJump = (item, type) => {
      if (useUserInfoStore?.userInfo?.driverId || type === 'back') {
        ctx.root.$router.push(item);
      } else {
        Toast('司机认证中，无法操作！');
      }
    };

    return {
      fleet,
      loading,
      handleJump,
      PageEnum
    };
  }
});
</script>
<style lang="less">
.fleet-wrap {
  background: #f1f3f5;
  min-height: 100vh;
  .btn-fix {
    width: 100vw;
    background: white;
    height: 0.72rem;
    left: 0;
    bottom: 0;
    position: fixed;
  }
}
</style>
