import { defineStore } from 'pinia';
import { useUserStore } from '@/store/user';
// import { apiSearchFleet } from '@/api/fleet';
import { apiSearchCarrier, apiSearchCarrierByIdCard } from '@/api/carrier';

export const useDriverFleetStore = defineStore({
  id: 'driver-fleet',
  state: () => ({
    fleet: []
  }),
  actions: {
    async queryCarrier() {
      const userInfo = useUserStore();
      try {
        // const res = await apiSearchCarrier({ driverId: userInfo.driverId });
        const res = await apiSearchCarrierByIdCard({ idCard: userInfo?.driverAuthVO?.idCard });
        const { data } = res;
        this.fleet = data;
      } catch (err) {
        console.log(err);
      }
    }
  }
});
